/* eslint-disable no-underscore-dangle */
import { afterDomContentLoaded } from 'saddlebag-browser';
import logger from 'saddlebag-logger';

import { loadGtmClient } from './gtmClient';
import { loadExternalScripts } from './scriptLoader';
import './public-path';
import { namespace } from './constants';

const loadAppRoot = async (props) => {
  const React = await import(/* webpackMode: "eager" */ 'react');
  const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');
  const { default: App } = await import(
    /* webpackMode: "eager" */ './components/App'
  );
  const container = document.getElementById('app-root');

  ReactDOM.render(React.createElement(App, props), container);
};

(async () => {
  try {
    await loadExternalScripts();
  } catch (error) {
    logger.logError(error, {
      message: `Failed to load dependencies ${error}`,
      component: namespace,
    });
  }

  afterDomContentLoaded(async () => {
    const { default: tagManager } = await import(
      /* webpackMode: "eager" */ './bootstrap/js-tag-manager'
    );
    const { props } = window.__internal;
    delete window.__internal.props;

    try {
      await loadAppRoot(props);
      await loadGtmClient();
      await tagManager();
    } catch (error) {
      logger.logError(error, {
        message: `Failed to initialise client side ${error}`,
        component: namespace,
      });
    }
  });
})();
