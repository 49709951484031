import scriptLoader from 'saddlebag-scriptloader';

const sharedLibsVersion = '3.1.0';

export const reactVersion = '17.0.2';
export const reactDomVersion = '17.0.2';

export const loadExternalScripts = () =>
  scriptLoader.queue(
    'redirects-ui',
    [
      `/sttc/shared-libs/${sharedLibsVersion}/react/react${reactVersion}.js`,
      `/sttc/shared-libs/${sharedLibsVersion}/react-dom/react-dom${reactDomVersion}.js`,
    ],
    { async: false },
  );
