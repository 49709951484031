/* eslint-disable no-underscore-dangle */
import { awaitComponent } from 'saddlebag-componentregistry';
import { GLOBAL } from 'saddlebag-browser';

import {
  TASK_TRANSPORT,
  TASK_FLIGHTS,
  TASK_CARHIRE,
  TASK_HOTEL,
  TASK_AIRPORT_TRANSFER,
} from '../constants';

const $window = GLOBAL.getWindow();
const doc = GLOBAL.getDocument();
const { tagManagerProps, tagManagerVersion } = $window.__internal;

const taskVerticalMapping = {
  [TASK_TRANSPORT]: 'transport',
  [TASK_FLIGHTS]: 'transport',
  [TASK_CARHIRE]: 'carhire',
  [TASK_HOTEL]: 'hotels',
  [TASK_AIRPORT_TRANSFER]: 'airport_transfer',
};

export function extractTaskVertical(skippyTask) {
  return taskVerticalMapping[skippyTask] || 'unknown';
}

function flattenParam(param) {
  if (param instanceof Array) {
    return param.length === 0 ? '' : param[param.length - 1];
  }
  return param;
}

function toBooleanOrUndefined(consent) {
  const lowerConsent = (flattenParam(consent) || '').toLowerCase();
  if (!['true', 'false'].includes(lowerConsent)) {
    return undefined;
  }
  return lowerConsent === 'true';
}

function extractIsApp(queryChannel) {
  const channel = flattenParam(queryChannel);

  if ([undefined, null, ''].includes(channel)) {
    return undefined;
  }
  const lowerChannel = channel.toLowerCase();
  if (['iphone', 'android', 'ipad'].includes(lowerChannel)) {
    return true;
  }
  return false;
}

export function buildExtraProps(href) {
  const skippyUrl = new URL(href);
  const [
    ,
    skippyTask,
    ,
    ,
    ,
    ,
    ,
    itineraryLegCount,
    itineraryLegs,
    skippyProductId,
    skippyCategoryId,
    skippySourceId,
  ] = skippyUrl.pathname.split('/');

  const { searchParams } = skippyUrl;
  const consentAdverts = toBooleanOrUndefined(
    searchParams.get('consent_adverts'),
  );
  const consentInformation = toBooleanOrUndefined(
    searchParams.get('consent_information'),
  );
  const isApp = extractIsApp(searchParams.get('channel'));
  const skippyTaskVertical = extractTaskVertical(skippyTask);

  return {
    redirectValue: searchParams.get('ticket_price'),
    itineraryLegCount,
    itineraryLegs,
    skippyProductId,
    skippyCategoryId,
    skippySourceId,
    skippyTaskVertical,
    consentAdverts,
    consentInformation,
    skippyUrl: skippyUrl.href,
    isApp,
  };
}

export default async () => {
  const { href } = window.location;
  // eslint-disable-next-line no-unused-vars
  const React = await import(/* webpackMode: "eager" */ 'react');
  const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');
  const extraProps = buildExtraProps(href);

  Object.assign(tagManagerProps, extraProps);

  awaitComponent(
    {
      name: 'js-tag-manager',
      version: tagManagerVersion,
    },
    ({ componentDefinition: Component, props }) => {
      const renderProps = Object.assign(props, tagManagerProps);
      ReactDOM.render(
        <Component {...renderProps} />,
        doc.getElementById('tag-manager-react'),
      );
    },
  );
};
