import PropTypes from 'prop-types';

export const Carhire = ({
  has_partner_logo: hasPartnerLogo,
  imagePath,
  localisation,
  logo,
  logo_alt_text: logoAltText,
  partner_logo: partnerLogo,
  query_api_pbs: queryApiPbs,
  websiteName,
}) => {
  const {
    ktxtDA_RedirectPage_CarHire_Step1: ktxtDaRedirectPageCarHireStep1,
    ktxtDA_RedirectPage_Header: ktxtDARedirectPageHeader,
    ktxtDA_RedirectPage_Step3: ktxtDaRedirectPageStep3,
    ktxtRedirectPage_PoweredBy: ktxtRedirectPagePoweredBy,
  } = localisation;

  return (
    <div id="Redirect" className="redirect-container">
      <h1>{ktxtDARedirectPageHeader}</h1>
      <ol className="Transfer">
        <li>
          <div>
            <img src={logo} alt={logoAltText} />
          </div>
          <p className="StepText">{ktxtDaRedirectPageCarHireStep1}</p>
        </li>
        <li className="Animation">
          <div>
            <img
              src={`${imagePath}/progress_rebrand.gif`}
              alt="TransferringDesktop"
              className="MaxTransfer"
            />
            <img
              src={`${imagePath}/progress_mobile_rebrand.gif`}
              alt="TransferringMobile"
              className="MinTransfer"
            />
          </div>
          <p />
        </li>
        <li>
          {hasPartnerLogo && (
            <div>
              <img src={partnerLogo} alt={websiteName} className="Provider" />
            </div>
          )}
          {!hasPartnerLogo && (
            <div>
              <span className="provider-nologo">{websiteName}</span>
            </div>
          )}
          <p className="StepText">{ktxtDaRedirectPageStep3}</p>
        </li>
      </ol>
      <div className="Summary">
        {queryApiPbs && (
          <div className="ssdv-poweredby">
            <p>
              <span className="poweredby-text">
                {ktxtRedirectPagePoweredBy}
              </span>
              <img
                src={`${imagePath}/skyscanner-logo_rebrand.png`}
                alt="Skyscanner"
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

Carhire.propTypes = {
  has_partner_logo: PropTypes.bool.isRequired,
  imagePath: PropTypes.string.isRequired,
  localisation: PropTypes.shape({
    ktxtDA_RedirectPage_Header: PropTypes.string.isRequired,
    ktxtDA_RedirectPage_Step3: PropTypes.string.isRequired,
    ktxtDA_RedirectPage_CarHire_Step1: PropTypes.string.isRequired,
    ktxtRedirectPage_PoweredBy: PropTypes.string.isRequired,
  }).isRequired,
  logo: PropTypes.string.isRequired,
  logo_alt_text: PropTypes.string.isRequired,
  partner_logo: PropTypes.string.isRequired,
  query_api_pbs: PropTypes.bool.isRequired,
  websiteName: PropTypes.string.isRequired,
};
