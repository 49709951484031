import PropTypes from 'prop-types';

export const Hotel = ({
  has_partner_logo: hasPartnerLogo,
  imagePath,
  localisation,
  logo,
  logo_alt_text: logoAltText,
  partner_logo: partnerLogo,
  policyHeader,
  query_api_pbs: queryApiPbs,
  websiteName,
}) => {
  const {
    ktxtDA_HotelRedirectPage_Step1: ktxtDaHotelRedirectPageStep1,
    ktxtDA_RedirectPage_Step3: ktxtDaRedirectPageStep3,
    ktxtRedirectPage_IfNotRedirected: ktxtRedirectPageIfNotRedirected,
    ktxtRedirectPage_PoweredBy: ktxtRedirectPagePoweredBy,
  } = localisation;

  return (
    <>
      <div id="Redirect" className="redirect-container">
        <h1
          className="hotel-policy-header"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: policyHeader,
          }}
        />
        <ol className="Transfer">
          <li>
            <div>
              <img src={logo} alt={logoAltText} />
            </div>
            <p>{ktxtDaHotelRedirectPageStep1}</p>
          </li>
          <li className="Animation">
            <div>
              <img
                src={`${imagePath}/progress_rebrand.gif`}
                alt="TransferringDesktop"
                className="MaxTransfer"
              />
              <img
                src={`${imagePath}/progress_mobile_rebrand.gif`}
                alt="TransferringMobile"
                className="MinTransfer"
              />
            </div>
            <p />
          </li>
          <li>
            {hasPartnerLogo ? (
              <div>
                <img src={partnerLogo} alt={websiteName} className="Provider" />
              </div>
            ) : (
              <div>
                <span className="provider-nologo">{websiteName}</span>
              </div>
            )}

            <p>{ktxtDaRedirectPageStep3}</p>
          </li>
        </ol>
        <div className="Summary">
          {queryApiPbs && (
            <div className="ssdv-poweredby">
              <p>
                <span className="poweredby-text">
                  {ktxtRedirectPagePoweredBy}
                </span>
                <img
                  src={`${imagePath}/skyscanner-logo_rebrand.png`}
                  alt="Skyscanner"
                />
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="hotel-progress-bar bar-height">
        <span />
      </div>
      <div className="hotel-footer footer-height">
        <div className="hotel-footer-container">
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: ktxtRedirectPageIfNotRedirected,
            }}
          />
        </div>
      </div>
    </>
  );
};

Hotel.propTypes = {
  has_partner_logo: PropTypes.bool.isRequired,
  imagePath: PropTypes.string.isRequired,
  localisation: PropTypes.shape({
    ktxtDA_HotelRedirectPage_Step1: PropTypes.string,
    ktxtDA_RedirectPage_Step3: PropTypes.string,
    ktxtRedirectPage_GreatDealYouSaved: PropTypes.string,
    ktxtRedirectPage_IfNotRedirected: PropTypes.string,
    ktxtRedirectPage_OnlyXRoomsLeft: PropTypes.string,
    ktxtRedirectPage_PoweredBy: PropTypes.string,
  }).isRequired,
  logo: PropTypes.string.isRequired,
  logo_alt_text: PropTypes.string.isRequired,
  partner_logo: PropTypes.string.isRequired,
  policyHeader: PropTypes.string.isRequired,
  query_api_pbs: PropTypes.bool.isRequired,
  websiteName: PropTypes.string.isRequired,
};
