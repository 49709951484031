/* eslint-disable no-underscore-dangle */
import logger from 'saddlebag-logger';
import { initTagManager, sendEvent } from '@skyscanner-internal/gtm-client';

import { namespace } from './constants';

// see documentation for more details - https://skyscanner.atlassian.net/wiki/spaces/DTO/pages/1199314807/Google+Tag+Manager+in+redirects-ui
const loadGtmClient = () => {
  logger.logOperationalEvent({
    eventName: 'load-gtm-client',
    status: 'start',
  });
  try {
    const { gtmDataLayer } = window.__internal;
    if (gtmDataLayer !== undefined && gtmDataLayer.isGtmEnabled) {
      initTagManager(gtmDataLayer.market);
      sendEvent(gtmDataLayer);

      logger.logOperationalEvent({
        eventName: 'load-gtm-client',
        status: 'success',
        event: gtmDataLayer.event,
      });
    }
  } catch (error) {
    logger.logOperationalEvent({
      eventName: 'load-gtm-client',
      status: 'failed',
    });

    logger.logError(error, {
      message: `Failed to load gtmClient on ${namespace}: ${error}`,
      component: namespace,
    });
  }
};

export { loadGtmClient };
