// eslint-disable-next-line no-restricted-imports,no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

export const Rail = ({ imagePath, localisation }) => {
  const {
    ktxt_RailRedirectPage_BookYourTickets: ktxtRailRedirectPageBookYourTickets,
    ktxt_RailRedirectPage_TakingYouTo: ktxtRailRedirectPageTakingYouTo,
    ktxt_RailRedirectPage_TripCom: ktxtRailRedirectPageTripCom,
  } = localisation;
  return (
    <div className="rail-redirect-container">
      <div className="content">
        <h1 className="title">
          <BpkText textStyle={TEXT_STYLES.bodyLongform} tagName="span">
            {ktxtRailRedirectPageTakingYouTo}
          </BpkText>
        </h1>
        <img
          id="trip-logo"
          src={`${imagePath}/tripcom.svg`}
          alt={ktxtRailRedirectPageTripCom}
        />
        <div id="rail-loading">
          <div id="rail-indicator" />
        </div>
        <div id="rail-rectangle">
          <div className="strapline">
            <BpkText textStyle={TEXT_STYLES.bodyDefault} tagName="p">
              {ktxtRailRedirectPageBookYourTickets}
            </BpkText>
          </div>
          <img
            id="rail-logo"
            src={`${imagePath}/train.svg`}
            alt={ktxtRailRedirectPageTripCom}
          />
        </div>
      </div>
    </div>
  );
};

Rail.propTypes = {
  imagePath: PropTypes.string.isRequired,
  localisation: PropTypes.shape({
    ktxt_RailRedirectPage_TakingYouTo: PropTypes.string,
    ktxt_RailRedirectPage_BookYourTickets: PropTypes.string,
    ktxt_RailRedirectPage_TripCom: PropTypes.string,
  }).isRequired,
};
