/* eslint-disable jsx-a11y/control-has-associated-label, no-nested-ternary */
import PropTypes from 'prop-types';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { ReactComponent as BagLogo } from '../../assets/bag.svg';

export const Transport = ({
  cabin_class: cabinClass,
  has_partner_logo: hasPartnerLogo,
  imagePath,
  isB2B,
  isB2bRefreshEnabled,
  isBagsEnabled,
  localisation,
  logo,
  logo_alt_text: logoAltText,
  partner_logo: partnerLogo,
  query_api_pbs: queryApiPbs,
  ticketSummary,
  websiteName,
}) => {
  const {
    ktxtDA_BookingPanel_SkyscannerFees: ktxtDABookingPanelSkyscannerFees,
    ktxtDA_RedirectPage_Header: ktxtDaRedirectPageHeader,
    ktxtDA_RedirectPage_Step1: ktxtDaRedirectPageStep1,
    ktxtDA_RedirectPage_Step2: ktxtDaRedirectPageStep2,
    ktxtDA_RedirectPage_Step3: ktxtDaRedirectPageStep3,
    ktxtDA_RedirectPage_TicketHeader: ktxtDaRedirectPageTicketHeader,
    ktxtRedirectPage_A11y_BagsMessage: ktxtRedirectPageA11yBagsMessage,
    ktxtRedirectPage_BagsMessage: ktxtRedirectPageBagsMessage,
    ktxtRedirectPage_PoweredBy: ktxtDaRedirectPagePoweredBy,
    ktxtRedirectPage_TakingYouTo: ktxtRedirectPageTakingYouTo,
  } = localisation;
  const { ticketDetails, ticketTaxPx } = ticketSummary;
  return (
    <>
      {!isB2B ? (
        <div id="Redirect" className="transport-redirect-container">
          <div className="content">
            <BpkText
              textStyle={TEXT_STYLES.bodyLongform}
              tagName="h1"
              aria-label={ktxtDaRedirectPageStep2}
            >
              {ktxtRedirectPageTakingYouTo}
            </BpkText>
            {hasPartnerLogo ? (
              <img
                src={partnerLogo}
                alt={websiteName}
                className="partner-logo"
              />
            ) : (
              <div className="provider-nologo">
                <BpkText
                  tagName="h4"
                  textStyle={TEXT_STYLES.lg}
                  className="fallback-text"
                >
                  {websiteName}
                </BpkText>
              </div>
            )}
            <div className="transport-loading">
              <div className="transport-indicator" />
            </div>
            {isBagsEnabled && (
              <div className="bags-rectangle">
                <div className="bags-strapline">
                  <BpkText
                    textStyle={TEXT_STYLES.bodyDefault}
                    aria-label={ktxtRedirectPageA11yBagsMessage}
                    tagName="p"
                    dangerouslySetInnerHTML={{
                      __html: ktxtRedirectPageBagsMessage,
                    }}
                  />
                </div>
                <BagLogo className="bag-logo" alt="" />
              </div>
            )}
          </div>
        </div>
      ) : isB2B && isB2bRefreshEnabled ? (
        <div
          id="Redirect"
          className="transport-redirect-container redirect-b2b-container"
        >
          <div className="logo-container">
            <img src={logo} alt={logoAltText} />
          </div>
          <div className="content">
            <BpkText
              textStyle={TEXT_STYLES.bodyLongform}
              tagName="h1"
              aria-label={ktxtDaRedirectPageStep2}
            >
              {ktxtRedirectPageTakingYouTo}
            </BpkText>
            {hasPartnerLogo ? (
              <img
                src={partnerLogo}
                alt={websiteName}
                className="partner-logo"
              />
            ) : (
              <div className="provider-nologo">
                <BpkText
                  tagName="h4"
                  textStyle={TEXT_STYLES.lg}
                  className="fallback-text"
                >
                  {websiteName}
                </BpkText>
              </div>
            )}
            <div className="transport-loading">
              <div className="transport-indicator" />
            </div>
            <div className="Summary" id="powered">
              {queryApiPbs && (
                <div className="ssdv-poweredby poweredby-b2b-container">
                  <p>
                    <span className="poweredby-text">
                      {ktxtDaRedirectPagePoweredBy}
                    </span>
                    <img
                      src={`${imagePath}/skyscanner-logo_rebrand.png`}
                      alt="Skyscanner"
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div id="Redirect" className="redirect-container">
          <h1>{ktxtDaRedirectPageHeader}</h1>
          <ol className="Transfer">
            <li>
              <div>
                <img src={logo} alt={logoAltText} />
              </div>
              <p>{ktxtDaRedirectPageStep1}</p>
            </li>
            <li className="Animation">
              <div>
                <img
                  src={`${imagePath}/progress_rebrand.gif`}
                  alt="TransferringDesktop"
                  className="MaxTransfer"
                />
                <img
                  src={`${imagePath}/progress_mobile_rebrand.gif`}
                  alt="TransferringMobile"
                  className="MinTransfer"
                />
              </div>
              <p>{ktxtDaRedirectPageStep2}</p>
            </li>
            <li>
              {hasPartnerLogo ? (
                <div>
                  <img
                    src={partnerLogo}
                    alt={websiteName}
                    className="Provider"
                  />
                </div>
              ) : (
                <div>
                  <span className="provider-nologo">{websiteName}</span>
                </div>
              )}
              <p>{ktxtDaRedirectPageStep3}</p>
            </li>
          </ol>

          {ticketSummary.show && (
            <div className="Ticket">
              <h2>{ktxtDaRedirectPageTicketHeader}</h2>
              <div className="Itinerary">
                <table className="Detail">
                  <tbody>
                    {ticketDetails.map(
                      ({
                        formatted_date: formattedDate,
                        location_string: locationString,
                      }) => (
                        <tr key={locationString}>
                          <td
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: locationString,
                            }}
                          />
                          <td>{formattedDate}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
                <div className="Detail">
                  {hasPartnerLogo ? (
                    <img src={partnerLogo} alt={websiteName} />
                  ) : (
                    <div className="detail-nologo">{websiteName}</div>
                  )}
                </div>
                <div className="CabinClassAndTicketTax">
                  <span className="CabinClass">
                    <em>{cabinClass}</em>
                  </span>
                  <span className="TicketTax">
                    <strong>{ticketTaxPx}</strong>
                  </span>

                  {queryApiPbs && (
                    // We use dangerouslySetInnerHTML here as the content contains html from the API
                    <div // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: ktxtDABookingPanelSkyscannerFees,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="Summary" />
            </div>
          )}

          <div className="Summary">
            {queryApiPbs && (
              <div className="ssdv-poweredby">
                <p>
                  <span className="poweredby-text">
                    {ktxtDaRedirectPagePoweredBy}
                  </span>
                  <img
                    src={`${imagePath}/skyscanner-logo_rebrand.png`}
                    alt="Skyscanner"
                  />
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Transport.propTypes = {
  logo: PropTypes.string.isRequired,
  logo_alt_text: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  partner_logo: PropTypes.string.isRequired,
  websiteName: PropTypes.string.isRequired,
  has_partner_logo: PropTypes.bool.isRequired,
  ticketSummary: PropTypes.shape({
    show: PropTypes.bool,
    ticketDetails: PropTypes.arrayOf(
      PropTypes.shape({
        formatted_date: PropTypes.string,
        location_string: PropTypes.string,
      }),
    ).isRequired,
    ticketTaxPx: PropTypes.string,
  }).isRequired,
  cabin_class: PropTypes.string.isRequired,
  query_api_pbs: PropTypes.bool.isRequired,
  localisation: PropTypes.shape({
    ktxtDA_BookingPanel_SkyscannerFees: PropTypes.string,
    ktxtDA_RedirectPage_Header: PropTypes.string,
    ktxtDA_RedirectPage_Step2: PropTypes.string,
    ktxtDA_RedirectPage_Step1: PropTypes.string,
    ktxtDA_RedirectPage_Step3: PropTypes.string,
    ktxtDA_RedirectPage_TicketHeader: PropTypes.string,
    ktxtRedirectPage_A11y_BagsMessage: PropTypes.string,
    ktxtRedirectPage_BagsMessage: PropTypes.string,
    ktxtRedirectPage_PoweredBy: PropTypes.string,
    ktxtRedirectPage_TakingYouTo: PropTypes.string,
  }).isRequired,
  isB2B: PropTypes.bool.isRequired,
  isB2bRefreshEnabled: PropTypes.bool.isRequired,
  isBagsEnabled: PropTypes.bool.isRequired,
};
